import { useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Button, Dialog } from 'components/ui';
import { Headline, DiffView, ContactPerson, ApprovalGrid } from 'components/thestral';

import { CHARACTERS_URL, HTTP_METHOD, ROLES_URL } from 'const';
import { User } from 'types';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';

export function CharacterApproval() {
  const fetchFromBackend = useFetchFromBackend();
  const approvers = useFetch<User[]>(`${ROLES_URL}/APPROVER`);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(undefined);
  const [contact, setContact] = useState('');
  const { showSnackbar } = useSnackbar();

  const breadcrumbs = [
    {href: '/', title: t('Generic.Start')},
  ];

  function onRowClick(elem) {
    setCurrent(elem.row);
    setOpen(true);
  }

 function approve(state: boolean) {
    setLoading(true);
    const update = state ? 'approve' : 'disapprove';
    const body = JSON.stringify({responsible: contact});
    fetchFromBackend(`${CHARACTERS_URL}/${current.id}/${update}`, {method: HTTP_METHOD.PATCH, body})
      .then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;

          showSnackbar(`${t('Generic.ApprovalChange')} ${result}`, severity);
        }
      ) 
      .catch((error) => console.error(error))
      .finally(() => {
        setOpen(false);
        setCurrent(undefined);
        setContact('');
        setLoading(false);
      });
  }

  return (
    <Box sx={{marginTop: '50px'}}>
      <Headline title={t('Components.Characters.Approvals.Title')} breadcrumbs={breadcrumbs} />

      <Grid container spacing={2}>
        <ApprovalGrid onRowClick={onRowClick} />
      </Grid>
      { open && approvers &&
      <Dialog 
        width='900px'
        open={open}
        onClose={() => setOpen(false)}
        title={`${t('Components.Characters.Approvals.TitleShort')} - ${current.name}`}
        content={
          <>
          {loading && 
            <Box sx={{justifyContent: 'center', display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '10px'}}>
              <CircularProgress />      
            </Box>
          }
          {!loading &&
            <>
              <DiffView char={current} approvers={approvers.data} />
              <ContactPerson contact={contact} callback={(event) => setContact(event.target.value)} />
            </>
          }
          </>
        }
        actions={
          <>
            <Button onClick={() => approve(true)} color='success' text={<Trans i18nKey='Components.Characters.Approvals.Approve' />} />
            <Button onClick={() => approve(false)} color='error' text={<Trans i18nKey='Components.Characters.Approvals.Decline' />} />
          </>
        }
      />
      }
    </Box>
  );
}
