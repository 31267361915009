import { Box, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { AssignmentInd, Delete } from '@mui/icons-material';
import { t } from 'i18next';

import { ApprovalStatus, AdminViewDialog, AssignDialog, DeleteDialog, NewNpcDialog, ThestralDataGrid } from 'components/thestral';

import { EMPTY, HTTP_METHOD, NPCS_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { useEffect, useMemo, useState } from 'react';
import { Character } from 'types';
import { Button } from 'components/ui';
import { AccountPlus } from 'mdi-material-ui';
import { CharacterContext } from 'contexts';

export function AdminNonPlayerCharacterGrid() {

  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const { data, triggerRefetch } = useFetch<Character[]>(NPCS_URL);
  const [characters, setCharacters] = useState<Character[]>();
  const [char, setChar] = useState<Character>();
  const charContextValue = useMemo(() => ({ char, setChar }), [char, setChar]);

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [assignOpen, setAssignOpen] = useState<boolean>(false);
  const [newOpen, setNewOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  
  useEffect(() => {
    if (data) {
      setCharacters(data);
      setLoading(false);
    }
  }, [data]);

  const onDelete = (event, character: Character) => {
    event.stopPropagation();
    setDeleteOpen(true);
    setChar(character);
  };

  const onAssign = (event, character: Character) => {
    event.stopPropagation();
    setChar(character);
    setAssignOpen(true);
  };

  const onRowClick = (elem) => {
    setChar(elem?.row);
    setEditOpen(true);
  };

  const onShow = (event, character: Character) => {
    setLoading(true);
    event.stopPropagation();

    const body = JSON.stringify({publicNpc: !character?.publicNpc});
    fetchFromBackend(`${NPCS_URL}/${character?.id}/visibility`, {
      body,
      method: HTTP_METHOD.PATCH
    }).then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
          if (response.ok) {
            triggerRefetch();
          }

          setLoading(false);
          showSnackbar(`${t('Components.Admin.Character.VisibilityChange')} ${result}`, severity);
        }
      ) 
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  
  return (
    <Box>
      <Button color='success' startIcon={<AccountPlus />} text={t('Components.Admin.Character.NewNpc')} onClick={() => setNewOpen(true)} />
      <ThestralDataGrid 
        rows={characters || EMPTY}
        columns={[
          {field: 'name', headerName: t('Generic.CharacterName'), sortable: true, width: 350},
          {field: 'playerName', headerName: t('Generic.OtName'), sortable: true, flex: 1},
          {field: 'npcType', headerName: t('Components.NonPlayerCharacters.Type'), sortable: true, 
            valueGetter: (_, row) =>
              !row?.npcType ? '' : t(`Enum.NpcType.${row.npcType}`)
          },
          {field: 'actions', headerName: t('Generic.Actions'), width: 250, align: 'right', headerAlign: 'right',
            renderCell: (params: GridRenderCellParams) => {
              return ( 
                <Box>
                  <IconButton onClick={(event) => onAssign(event, params.row)}>
                    <AssignmentInd color='primary' />
                  </IconButton>
                  <ApprovalStatus state={params.row?.publicNpc} onClick={(event) => onShow(event, params.row)}/>
                  <IconButton onClick={(event) => onDelete(event, params.row)}>
                    <Delete color='primary' />
                  </IconButton>
                </Box>
              );
            }
          }
        ]}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 }
          },
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }]
          }
        }}
        onRowClick={onRowClick}
      />

      {char && 
        <DeleteDialog 
          open={deleteOpen} 
          onClose={() => setDeleteOpen(false)} 
          triggerRefetch={triggerRefetch}
          endpoint={`${NPCS_URL}/${char?.id}`}
          i18nBase='Components.Admin.Character'
        />
      }
      {char && 
        <AssignDialog
          open={assignOpen}
          onClose={() => setAssignOpen(false)}
          triggerRefetch={triggerRefetch}
          character={char}
        />
      }
      <NewNpcDialog 
        open={newOpen}
        onClose={() => setNewOpen(false)}
        triggerRefetch={triggerRefetch}
      />

      <CharacterContext.Provider value={charContextValue}>
        {char && <AdminViewDialog 
          open={editOpen}
          onClose={() => setEditOpen(false)}
          triggerRefetch={triggerRefetch}
          character={char}
          npc
        />
        }
      </CharacterContext.Provider>
    </Box>
  );
}