import { ReactNode, useEffect, useState } from 'react';
import { Box, CardMedia, CircularProgress, Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Button, Dialog, TextField } from 'components/ui';

import { DRAPERIES_URL, HTTP_METHOD, STATIC_URL } from 'const';
import { useFetchFromBackend, useSnackbar } from 'func';
import { DraperyCheck } from 'types';

type Props = {
  open: boolean
  drapery: DraperyCheck
  onClose: () => void
  fetchCallback: () => void
  onDelete: (evt, elem) => void
}

export function DraperyEdit(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const [note, setNote] = useState<string>(props.drapery?.note);
  const [loading, setLoading] = useState<boolean>();

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    setNote(props.drapery?.note);
  }, [props.drapery]);

  const onClose = () => {
    setNote('');
    props.onClose();
  };

  const onUpdate = () => {
    setLoading(true);
    const body = JSON.stringify({note});
    fetchFromBackend(`${DRAPERIES_URL}/${props.drapery?.id}`, {method: HTTP_METHOD.PUT, body})
      .then((response) => {
          const severity = response.status < 400 ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
          if (response.ok) props.fetchCallback();

          showSnackbar(`${t('Components.Drapery.NoteUpdate')} ${result}`, severity);
       }).finally(() => setLoading(false));
  };

  const onSubmit = () => {
    setLoading(true);
    fetchFromBackend(`${DRAPERIES_URL}/${props.drapery?.id}/submit`, {method: HTTP_METHOD.PUT})
      .then((response) => {
          const severity = response.status < 400 ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
          if (response.ok) {
            props.fetchCallback();
            props.onClose();
          }

          showSnackbar(`${t('Components.Drapery.Submission')} ${result}`, severity);
       }).finally(() => setLoading(false));
  };

  const onDelete = () => {
    props.onDelete(null, props.drapery);
  };

  function actions() : ReactNode {
    if (loading) return <></>;
    return (
      <>
        <Button onClick={onUpdate} color='secondary' sx={{ marginLeft: '5px' }} disabled={!note} text={<Trans i18nKey='Components.Drapery.Update' />} />
        <Button onClick={onDelete} color='error' sx={{ marginLeft: '5px' }} text={<Trans i18nKey='Components.Drapery.Delete' />} />
        {props.drapery?.status === 'DECLINED' &&
          <Button onClick={onSubmit} color='success' sx={{ marginLeft: '5px' }} text={<Trans i18nKey='Components.Drapery.SubmitAgain' />} />
        }
      </>
    );
  }

  function content() : ReactNode {
    if (loading) {
      return (
        <Box sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
          <CircularProgress />
        </Box>
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardMedia component='img' sx={{ maxHeight: '500px' }} image={`${STATIC_URL}/uploads/${props.drapery?.imagePath}`} alt='Thumbnail' />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id='drapery.note'
            defaultValue={note}
            label='Notiz'
            multiline
            rows={5}
            onChange={(event) => setNote(event.target.value)}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      title={t('Components.Drapery.Edit')}
      content={content()}
      actions={actions()}
    />
  );
}
