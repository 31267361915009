import { ReactNode } from 'react';
import { Box, Grid } from '@mui/material';

type Props = {
  image: string,
  content: ReactNode,
  reversed?: boolean
}

export function RulesBox(props: Readonly<Props>) : ReactNode {
  const isReversed = props.reversed;
  const content = (
      <Box 
        component='img'
        sx={{
           height: '470px',
           width: '470px',
        }}
        src={props.image}
        alt='RulesBox Image'
      />
  );

  return (
   <>
    <Grid
      item
      xs={6}
      sx={{
        display: 'flex',
        justifyContent: 'right',
        paddingRight: '25px',
        alignItems: 'center',
        marginTop: '32px'
      }}
    >
      {isReversed ? props.content : content}
    </Grid>
    <Grid
      item
      xs={6}
      sx={{
        display: 'flex',
        paddingLeft: '25px',
        alignItems: 'center',
        marginTop: '32px'
      }}
    >
      {isReversed ? content : props.content}
    </Grid>
   </>
  );
}