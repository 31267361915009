import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { LinkText } from 'components/ui';

type Props = {
  title: string,
  href?: string,
  id?: string
}

export function RulesBlockTitle(props: Readonly<Props>): ReactNode {
  return (
    <Typography
      variant='h1'
      component='p'
      id={props.id}
      sx={{
        fontSize: '1.6rem',
        fontWeight: '400',
        textAlign: 'left',
        lineHeight: '50px',
        color: '#ffffff',
        fontFamily: 'Bebas'
      }}>
      {props.title}
      {props.href && 
        <LinkText to={props.href} underline='none'>
          ^
        </LinkText> 
      }
    </Typography>
  );
}