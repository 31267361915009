import { ReactNode } from 'react';
import { Box, DialogActions, DialogTitle, Dialog as MuiDialog, DialogContent, SxProps, Typography, AppBar, IconButton, Toolbar, Grid } from '@mui/material';

import { ThestralDialogTransition } from 'components/thestral';
import { CloseButton } from 'components/ui';
import { ArrowBackIosNew } from '@mui/icons-material';

type Props = {
  open: boolean
  onClose?: () => void
  content?: ReactNode
  title?: string
  titleRightIcon?: ReactNode
  caption?: string
  actions?: ReactNode
  fullWidth?: boolean
  fullScreen?: boolean
  sx?: SxProps
  noActions?: boolean
  width?: string
  titleButton?: ReactNode
}

export function Dialog(props: Readonly<Props>): ReactNode {
  return (
    <MuiDialog 
      sx={{
          ...props.sx, 
          '& .MuiDialog-paper': {
            backgroundColor: '#1e1e1e',
            marginBottom: '0',
            minWidth: props?.width || '1200px'
          }
        }}
       open={props.open} 
       onClose={props.onClose} 
       fullWidth={props.fullWidth}
       TransitionComponent={ThestralDialogTransition}
       fullScreen={props.fullScreen}
      >
      {props.fullScreen ?
        <AppBar color='secondary' sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton autoFocus edge='start' color='inherit' onClick={props.onClose} aria-label='close'>
              <ArrowBackIosNew color='primary' />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {props.title}
            </Typography>

            <CloseButton onClick={props.onClose} />
          </Toolbar>
        </AppBar>
      :
        <DialogTitle sx={{paddingBottom: '0px'}}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h5' component='p' sx={{ color: '#bcbcbc' }}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ justifyContent: 'end', display: 'flex' }}>
              { props.titleButton }
            </Grid>
          </Grid>
          { props.caption &&
            <Typography variant='caption' component='span' color='gray'>
              {props.caption} 
            </Typography>  
          }
        </DialogTitle> 
      }
      <DialogContent>{props.content}</DialogContent>
      {!props.noActions &&
      <DialogActions>
        {props.actions}
        <Box style={{flex: '1 0 0'}} />
        <CloseButton onClick={props.onClose} />
      </DialogActions>
      }
    </MuiDialog>
  );
}