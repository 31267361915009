import { Box, Typography } from '@mui/material';
import { t } from 'i18next';

import { Headline, SignupForm } from 'components/thestral';
import { ReactNode } from 'react';

export function Signup(): ReactNode {

  return (
    <Box className='content' sx={{marginTop: '50px', width: '900px'}}>
      <Headline title={t('Components.Greeting.Welcome')} marginBottom='10px' />
      <Typography component='div' variant='h6'>
        {t('Components.Signup.Caption')}
      </Typography>
      <Typography component='div' variant='caption'>
        {t('Components.Signup.DescriptionText')}
      </Typography>

      <SignupForm />
    </Box>
  );
}
