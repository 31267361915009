import { ReactNode } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { Button } from 'components/ui';

type Props = {
  title: string,
  text: string,
  dates?: string,
  itDates?: string,
  category?: string,
  to?: string
  con?: boolean
}
export function RulesContent(props: Readonly<Props>): ReactNode {
  return (
    <Grid container spacing={2} sx={{padding: '12px', width: '470px', textAlign: 'center'}}>
      {props.category && <Grid item xs={12}>
        <Card
          sx={{
            borderRadius: '0px',
            padding: '1px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            color: '#000',
            fontSize: '0.95rem',
            backgroundColor: '#dedede',
            width: '40px',
            fontFamily: 'Bebas'
          }}
        >
          {props.category}
        </Card>
      </Grid>}
      <Grid item xs={12}>
        <Typography
          variant='h1'
          component='p'
          sx={{
            fontWeight: '500',
            lineHeight: '2rem',
            color: '#fff',
            fontFamily: 'Bebas',
            fontSize: '2.4em',

          }}>
          {props.title}
        </Typography>
      </Grid>
      {props.dates &&
      <Grid item xs={6}>
        <Typography
          variant='h6'
          component='p'
          color='secondary'
          sx={{
            marginTop: '-15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
          }}
        >
          <Card
            sx={{
              borderRadius: '0px',
              padding: '1px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              color: '#000',
              fontSize: '0.95rem',
              backgroundColor: '#dedede',
              width: '40px',
              fontFamily: 'Bebas'
            }}
          >
            OT
          </Card>
          {props.dates}
        </Typography>
      </Grid>
      }
      {props.itDates &&
      <Grid item xs={6}>
        <Typography
          variant='h6'
          component='p'
          color='primary'
          sx={{
            marginTop: '-15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
          }}
        >
          <Card
            sx={{
              borderRadius: '0px',
              padding: '1px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              color: '#000',
              fontSize: '0.95rem',
              backgroundColor: '#dedede',
              width: '40px',
              fontFamily: 'Bebas'
            }}
          >
            IT
          </Card>
          {props.itDates}
        </Typography>
      </Grid>
      }

      <Grid item xs={12}>
        <Typography
          variant='body1'
          component='p'
          sx={{
            color: 'rgba(255, 255, 255, 0.7)',
            fontSize: '14px',
            fontFamily: 'Neuton',
            letterSpacing: '0.0071428571em',
            whiteSpace: 'pre-line',
            textAlign: 'left'
          }}

        >
          {props.text}
        </Typography>
      </Grid>
      {props.to && <Grid item xs={12}>
        <Button
          color='primary'
          href={props.to}
          sx={{
            color: '#ffffff',
            letterSpacing: '0.0892857143em'
          }}
          text={<Trans i18nKey={props.con ? 'Components.Rules.WorldBuilding.Dailyprophet' : 'Components.Rules.ReadOn'} />}
        />
      </Grid>
      }
    </Grid>
  );
}