import { ReactNode, useState, useContext } from 'react';
import { Box, CardMedia, CircularProgress, Typography } from '@mui/material';
import { Fort } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { EVENTS_URL, HTTP_METHOD } from 'const';
import { UserContext } from 'contexts';
import { parseDate, useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { Event } from 'types';

import { CardItem, OwlpostNote } from 'components/thestral';
import { Button } from 'components/ui';

type Props = {
  item: Event
}

export function EventCardItem(props: Readonly<Props>): ReactNode {
  const event = props.item;
  const EVENT_URL = `${EVENTS_URL}/${event?.id}`;

  const {user} = useContext(UserContext);
  const fetchFromBackend = useFetchFromBackend();
  const { data: status, triggerRefetch } = useFetch<string>(`${EVENT_URL}/status/${user?.id}`);

  const { showSnackbar } = useSnackbar();  
  const [loading, setLoading] = useState(false);

  function colorStatus() {
    switch(status) {
      case 'REGISTERED': return '#eab345';
      case 'PAID': return '#006207';
      case 'UNREGISTERED': return '#5f787b';
      case 'CANCELLED': return '#ff5252';
      default: return '#5f787b';
    }
  }

  const changeRegistration = (state) => {
    const update = state ? 'signup' : 'cancel';
    setLoading(true);
    fetchFromBackend(`${EVENT_URL}/${update}/${user?.id}`, {
      method: HTTP_METHOD.PUT
    }).then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
          
          if (response.ok) {
            triggerRefetch();
          }

          const i18nKey = state ? 'SignUp' : 'Cancel';
          showSnackbar(`${t('Components.Events.'+i18nKey+'.Post')} ${result}`, severity);
        }
      ).finally(() => setLoading(false));
  };

  return (
    <CardItem
      dialogTitle={event?.name}
      dialogActions={(
        <>
          {status !== 'REGISTERED' && status !== 'PAID' &&
            <Button color='success' onClick={() => changeRegistration(true)} text={t('Components.Events.SignUp.Title')} />
          }
          {status !== 'CANCELLED' &&
            <Button color='error' onClick={() => changeRegistration(false)} text={t('Components.Events.Cancel.Title')} />
          }
        </>
      )}
      dialogContent={(
        <>
          <Typography variant='h6' sx={{ color: '#9e9e9e', textAlign: 'center' }}>
            <Trans i18nKey='Components.Events.Status' />
          </Typography>
          <Typography variant='h6' sx={{ color: colorStatus(), textAlign: 'center' }}>
            {t('Enum.ParticipationStatus.'+status)}
          </Typography>
          {status === 'PAID' && 
            <Box sx={{justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '10px', flexDirection: 'column'}}>
              <OwlpostNote event={event}/>
            </Box>
          }
        </>
      )}
      cardMedia={(
        <CardMedia
          sx={{ 
            height: '180px',
            margin: 'auto',
            width: '65%',
            paddingTop: '10px',
            cursor: 'pointer'
          }}
        >
          <Fort 
            sx={{
              width: '100%',
              height: '100%'
            }}
          />
        </CardMedia>
      )}
      cardContent={(
        <>
          {loading ?
            <CircularProgress />
          :
            <>
              <Typography variant='h5' gutterBottom sx={{ textAlign: 'center', marginBottom: '0' }} color='black'>
                { event?.name }
              </Typography>
              <Typography variant='h6' sx={{ color: '#9e9e9e', textAlign: 'center' }}>
                { parseDate(event?.start) } - { parseDate(event?.end) }
              </Typography>
              <Typography variant='h6' sx={{ color: colorStatus(), textAlign: 'center' }}>
                {t('Enum.ParticipationStatus.'+status)}
              </Typography>
            </>
          }
        </>
      )}
    />
  );
}
