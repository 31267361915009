import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type Props = {
  caption: string,
  marginTop?: string
}

export function RulesBlockCaption(props: Readonly<Props>): ReactNode {
  return (
    <Typography
      variant='body1'
      component='p'
      sx={{
        fontFamily: 'Bebas',
        color: 'rgba(255, 255, 255, 0.5)',
        fontSize: '1.3rem',
        letterSpacing: '0.0125em',
        marginTop: props.marginTop
      }}
    >
      {props.caption}
    </Typography>
  );
}