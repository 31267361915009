import { ReactNode } from 'react';
import { Typography } from '@mui/material';

import { Breadcrumbs } from 'components/ui';

import { CustomLink } from 'types';

type Props = {
  title: string,
  breadcrumbs?: CustomLink[]
  marginBottom?: string
}

export function Headline(props: Readonly<Props>) : ReactNode {
  return (
    <>
      {props.breadcrumbs && <Breadcrumbs
        site={props.title} 
        links={props.breadcrumbs}
      />}
      <Typography 
        variant='h1' 
        component='p' 
        sx={{ 
          fontSize: '3rem', 
          fontWeight: '700', 
          marginBottom: props.marginBottom ?? '60px',
          textAlign: 'left',
          lineHeight: '40px'
        }}>
          {props.title}
      </Typography>
    </>
  );
}