import { Box, styled } from '@mui/material';
import { Trans } from 'react-i18next';

export const StyledGridOverlay = styled('div') (() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
}));

export function ThestralDataGridOverlay() {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 2 }}>
        <Trans i18nKey='Generic.NoData' />
      </Box>
    </StyledGridOverlay>
  );
}