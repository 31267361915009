import { ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'components/ui';

type Props = {
  onClick: () => void
}

export function CloseButton(props: Readonly<Props>) : ReactNode {
  return (
    <Button 
      onClick={props.onClick} 
      color='error' 
      text={<Trans i18nKey='Generic.Close' />} 
    />
  );
}