import {ReactNode} from 'react';
import {Typography} from '@mui/material';

type Props = {
  text: string,
  fontSize?: string,
  color?: string
}

export function RulesParagraph(props: Readonly<Props>): ReactNode {
  return (
    <Typography
      variant='body1'
      component='p'
      sx={{
        fontFamily: 'Neuton',
        color: props.color || 'rgba(255, 255, 255, 0.7)',
        fontSize: props.fontSize || '1.1rem',
        letterSpacing: '0.16px',
        padding: '0 0 10px 0',
        fontWeight: '400',
        lineHeight: '1.375rem'
      }}
    >
      {props.text}
    </Typography>
  );
}