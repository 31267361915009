import { AppBar, Toolbar } from '@mui/material';

import { LogoutMenu } from './LogoutMenu';
import { BurgerMenu } from './BurgerMenu';

export function Navigation() {
  return (
    <AppBar position='static' color='secondary'>
      <Toolbar>
        <BurgerMenu />
        <LogoutMenu />
      </Toolbar>
    </AppBar>
  );
}